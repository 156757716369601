async function loadConfig() {
    try {
        const response = await fetch('/config.json')
        if (!response.ok) {
            window.hasRuntimeEnv = false
        }
        window.hasRuntimeEnv = true
        window.appRuntimeEnv = await response.json()
    } catch (error) {
        window.hasRuntimeEnv = false
    }
}
loadConfig().then(() => {
    return import('./app.js')
})
